import React from "react";
import {
  Container,
  Title,
  SubTitle,
  Content,
  Wrapper,
  ContentsContainer,
  ImageContainer,
  StyledImg,
} from "./styles";
import Image from "./Images";
import PageContainer from "../PageContainer";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
const OurTeam = () => {
  const image = Image();
  return (
    <>
      <Breadcrumb pageTitle="Our Team">
        <BreadcrumbItem Link="/our-Team" Text="Our Team" />
      </Breadcrumb>

      <PageContainer>
        <Container>
          <Container>
            <Content>
              Our staff aim to provide you with a personalised and enjoyable
              experience during your orthodontic journey. Our team aims to
              provide you with clinical excellence by using the latest
              orthodontic principles and techniques.
            </Content>
            <Content>
              There are many people in the Gippsland Orthodontic team who assist
              our orthodontist in giving you the best possible outcome in your
              treatment.
            </Content>
            <Title>Meet our team at Gippsland Orthodontics</Title>
            <SubTitle>Will - Practice Manager</SubTitle>
            <Wrapper>
              <ImageContainer>
                <StyledImg fluid={image.will} />
              </ImageContainer>

              <ContentsContainer>
                <Content>
                  Will has had a long history of working in management roles and
                  has recently joined the Gippsland Orthodontic team to learn
                  about all thing’s orthodontics! Originally from Latrobe in the
                  northern parts of Tasmania, he moved to Melbourne and has
                  worked as a relationship manager for several companies in the
                  finance industry.
                </Content>
                <Content>
                  Will as the practice manager loves seeing the results patients
                  get and how happy they are. He is also well known for giving
                  out some special treats to patients along the way (especially
                  if they are hangry)...
                </Content>
                <Content>
                  In his spare time, he enjoys spending time with his family and
                  friends, especially the ones of the animal variety. He can
                  never say no to a hello and a cuddle from any animal...
                  particularly his dogs, Hughie and Brown Dog.
                </Content>
              </ContentsContainer>
            </Wrapper>
            <SubTitle>Tameka - Treatment Coordinator</SubTitle>
            <Wrapper>
              <ImageContainer>
                <StyledImg fluid={image.tameka} />
              </ImageContainer>
              <ContentsContainer>
                <Content>
                  Tameka has been working as an Orthodontic treatment
                  coordinator for 7 years in Melbourne alongside a very dear
                  colleague of Dr. Adams’. Tameka enjoys working closely with
                  the team, interacting with patients, to ensure they receive
                  the best care and advice.
                </Content>
                <Content>
                  Tameka moved to the West Gippsland area in 2020 to live with
                  her partner on his parent's dairy farm. She has since had a
                  beautiful baby boy and is a step mother to three children.
                </Content>
                <Content>
                  Tameka enjoys cooking, reading, travelling and spending time
                  with her family.
                </Content>
              </ContentsContainer>
            </Wrapper>
            <SubTitle>Kate - Dental Assistant and Receptionist</SubTitle>
            <Wrapper>
              <ImageContainer>
                <StyledImg fluid={image.kate} />
              </ImageContainer>
              <ContentsContainer>
                <Content>
                  Kate has been caring for dental patients for over 20 years.
                  Kate was first involved in the area of general dentistry
                  following the completion her Certificate III in Dental
                  Assisting in 2000.
                </Content>
                <Content>
                  Kate found her real passion as a dental assistant when she
                  started assisting in the specialised area of orthodontics. She
                  loves seeing the patients from the start to finish of
                  treatment! And the amazing transformation of the patients’
                  beautiful smiles! Kate has been involved in orthodontics for
                  over 4 years and loves to work chair side assisting and the
                  interaction with the patients.
                </Content>
                <Content>
                  In Kate’s spare time she enjoys spending time with her 2 young
                  adult children, Jasmyn and Samuel. She also catching up with
                  friends, shopping, enjoys watching the AFL and cricket.... but
                  most of all, loves to walk her beautiful black Labrador,
                  Dusty, as he means the world to her (as well as two jealous
                  children).
                </Content>
              </ContentsContainer>
            </Wrapper>
            <SubTitle>Samantha - Dental Assistant and Receptionist</SubTitle>
            <Wrapper>
              <ImageContainer>
                <StyledImg fluid={image.samantha} />
              </ImageContainer>
              <ContentsContainer>
                <Content>
                  Samantha comes from an administration and health care
                  background and is now pursuing her long dream of becoming a
                  Dental Assistant. Samantha cares about helping others, and is
                  excited for the opportunity to see our clients' smiles
                  transform.
                </Content>
                <Content>
                  In her spare time, Samantha enjoys travelling with her family,
                  experiencing different cultures, traditions and living in the
                  moment.
                </Content>
                <Content>
                  In 2015, Samantha and her family moved to Gippsland to escape
                  the hustle and bustle of suburbia. She now considers Gippsland
                  her home and is enjoying all the things that the local area
                  has to offer.
                </Content>
              </ContentsContainer>
            </Wrapper>
            <SubTitle>Teighan - Dental Assistant and Receptionist</SubTitle>
            <Wrapper>
              <ImageContainer>
                <StyledImg fluid={image.teighan} />
              </ImageContainer>
              <ContentsContainer>
                <Content>
                  Teighan has been working in the dental field for many years
                  and has completed her Certificate III in Dental Assisting.
                  Teighan and her grandmother, Kerry, have previously worked
                  with Dr Adam in Melbourne for many years. Teighan enjoys
                  helping people, being hands on and seeing people happy. In her
                  spare time, she is outdoors with her horses and her best
                  buddy, Bruce, the dog. Always by her side is her son Hunter
                  who loves the great outdoors and animals.{" "}
                </Content>
              </ContentsContainer>
            </Wrapper>
          </Container>
        </Container>
      </PageContainer>
    </>
  );
};

export default OurTeam;
