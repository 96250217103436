import styled from "styled-components";
import Img from "gatsby-image";
import { breakpoints } from "../../styles/variables";

export const Container = styled.div`
  display: block;
  margin-bottom: 2rem;
`;

export const Title = styled.h1`
  font-size: 1.3rem;
  padding-bottom: 2rem;
`;

export const SubTitle = styled.h2`
  font-size: 1.125rem;
  padding: 0 3rem 0.25rem 3rem;
  @media (min-width: ${breakpoints.sm}) {
    margin: 1rem 0 2rem 0;
    padding: 0 0 0.25rem 0;
  }
`;

export const Content = styled.p`
  padding-bottom: 1rem;
  line-height: 1.8rem;
  font-family: "Red Hat Display", sans-serif;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 1rem;
  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
  }
`;

export const ContentsContainer = styled.div`
  flex-basis: 80%;
  padding: 1.5rem 3rem;
  @media (min-width: ${breakpoints.md}) {
    padding: 2rem 6rem;
  }
  @media (min-width: ${breakpoints.lg}) {
    margin: 1.5rem 0 0 3rem;
    padding: 0;
  }
`;

export const ImageContainer = styled.div`
  flex-basis: 20%;
  border-radius: 0%;
  margin-left: 0;
  margin: 0.5rem 0 0 0;
  padding: 0.5rem 3rem;
  @media (min-width: ${breakpoints.md}) {
    margin: 1rem 0 2rem 0;
    padding: 0;
  }
  @media (min-width: ${breakpoints.lg}) {
    margin: 1rem 0 5rem 0;
    padding: 0;
  }
`;

export const StyledImg = styled(Img)`
  border-radius: 1rem;
`;
