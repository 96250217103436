import { useStaticQuery, graphql } from "gatsby";

const Image = () => {
  const data = useStaticQuery(graphql`
    fragment ImageStaffPhotos on File {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    query {
      will: file(relativePath: { eq: "about/IMG_6269.jpeg" }) {
        ...ImageStaffPhotos
      }
      kate: file(relativePath: { eq: "about/IMG_6271.jpeg" }) {
        ...ImageStaffPhotos
      }
      teighan: file(relativePath: { eq: "about/IMG_6277.jpeg" }) {
        ...ImageStaffPhotos
      }
      samantha: file(relativePath: { eq: "about/samantha.jpeg" }) {
        ...ImageStaffPhotos
      }
      tameka: file(relativePath: { eq: "about/tameka.jpeg" }) {
        ...ImageStaffPhotos
      }
    }
  `);

  return {
    will: data.will.childImageSharp.fluid,
    kate: data.kate.childImageSharp.fluid,
    teighan: data.teighan.childImageSharp.fluid,
    samantha: data.samantha.childImageSharp.fluid,
    tameka: data.tameka.childImageSharp.fluid,
  };
};
export default Image;
