import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import OurTeam from "../components/our-team";

const AboutOurTeamPage = () => (
  <Layout>
    <SEO title="About Our Team" />
    <OurTeam />
  </Layout>
);

export default AboutOurTeamPage;
